<template>
  <footer>
    <p>Copyright 2022 pms-oj org. distributed as GPL-3.0 (included MIT-licensed components)<br/>https://github.com/pms-oj/pms</p>
  </footer>
</template>

<style lang="scss">
footer{
  position: absolute;
  width: 100%;
  bottom: 56px;
  height: 140px;
  background-color: #EEEEEE;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}
</style>
