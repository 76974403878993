<template>
  <form class="search-form">
    <div style="text-align: center; justify-content: center;">
      <div class="input-box">
        <img src="../assets/magnifyGlass.png" class="search-icons">
        <input type="search" value="" placeholder="Let’s go to explore interesting problems ..." class="search-input">
      </div>
    </div>
  </form>
</template>

<style lang="scss">
.input-box{
  margin-top: 85px;
  display: flex;
  margin: 70px;
  padding: 6px;
  height: 70px;
  border-radius: 15px;
  border: 3px solid var(--Black);
}
.input-box input{
  background-color: var(--Background);
  flex: 7;
  max-width: 100%;
  border: none;
  outline: none;
  font-family: 'IBMPlexSansKR-Regular';
  font-size: 25px;
}
.search-icons {
  width: 32px;
  height: 32px;
  margin: 10px;
}

button{
  margin-bottom: 15px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
