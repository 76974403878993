<template>
  <div class="container codeEditor">
    <CodeEditor
      :language_selector="true"
      :languages="this.supportLangs"
      width="100%"
      height="100%"
      border_radius="4px"
      selector_width="150px"
      max_height="100vh"
      min_height="100vh"
      />
  </div>
  <div class="container problem">
    <div v-html="markdownToHtml"></div>
  </div>
</template>

<script lang="js">
/* eslint-disable */
import katex from 'katex'
import Prism from 'prismjs'
import emoji from 'node-emoji'
import { defineComponent } from 'vue'
import CodeEditor from '@/components/CodeEditor.vue'
import { parse, setOptions } from 'marked-katex-yco'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-c'
import 'prismjs/components/prism-cpp'

export default defineComponent({
  name: 'HomeView',
  components: {
    CodeEditor
  },
  computed: {
    markdownToHtml () {
      return this.parse(this.markdown)
    }
  },
  props: {
    markdown: {
      type: String,
      default: '[google](https://google.com)'
    },
  },
  methods: {
    unLoadEvent (event) {
      if (this.canLeaveSite) return

      event.preventDefault()
      event.returnValue = ''
    },
    parse (markdown) {
      const replacer = (match) => emoji.emojify(match)
      markdown = markdown.replace(/(:.*:)/g, replacer)
      return parse(markdown)
    }
  },
  data () {
    return {
      supportLangs: [
        ['python', 'Python2'],
        ['python', 'Python3'],
        ['python', 'Python2 (PyPy2)'],
        ['python', 'Python3 (PyPy3)'],
        ['cpp', 'C++98'],
        ['cpp', 'C++03'],
        ['cpp', 'C++11'],
        ['cpp', 'C++14'],
        ['cpp', 'C++17'],
        ['cpp', 'C++20'],
        ['c', 'C99'],
        ['c', 'C11'],
        ['c', 'C17'],
        ['java', 'Java8'],
        ['java', 'Java11'],
        ['java', 'Java17'],
        ['rust', 'Rust 2015'],
        ['rust', 'Rust 2018'],
        ['rust', 'Rust 2021']
      ]
    }
  },
  mounted () {
    window.addEventListener('beforeunload', this.unLoadEvent)
    const renderer = {
      link (href, title, text) {
        const html = this.link.call(renderer, href, title, text)
        return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ')
      },
      code (code, infostring) {
        code = this.options.highlight(code, infostring)
        return `<pre class = "language-${infostring}"><code class = "language-${infostring}">${Prism.highlight(
          code,
          Prism.languages[infostring],
          infostring
        )}</code></pre>`
      },
      codespan (code) {
        if (code[0] === '$') {
          return katex.renderToString(code.substring(1), {
            throwOnError: false
          })
        } else {
          return `<code class = "inline-code">${code}</code>`
        }
      }
    }
    setOptions({
      renderer,
      highlight: (code, lang) => {
        if (lang === 'math') return katex.renderToString(code, { throwOnError: false })
        if (Prism.languages[lang]) {
          return Prism.highlight(code, Prism.languages[lang], lang)
        } else {
          return code
        }
      }
    })
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.unLoadEvent)
  },
  beforeRouteLeave(to, from, next) {
    if (this.canLeaveSite) next()
    else if (confirm('이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.')) {
      next()
    }
  }
})
</script>

<style lang="scss">

blockquote {
  padding-left: 20px;
  border-left: 3px solid #ffc0cb;
}

.container {
  width: 50%;
  height: 50%;
  flex: auto;
}

.codeEditor {
  float: right;
}

.problem {
  float: left;
  text-align: left;
  padding-left: 5vh;
  padding-right: 5vh;
  padding-top: 20px;
  overflow: scroll;
  max-height: 100vh;
}

code {
  font-family: MyFancyCustomFont, monospace;
  font-size: inherit;
}

/* Code in text */
p > code,
li > code,
dd > code,
td > code {
  background: #ffeff0;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: .1rem .3rem .2rem;
  border-radius: .2rem;
}

pre code {
  display: block;
  background: none;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  max-width: 100%;
  min-width: 100px;
  padding: 0;
}

h1 {
  border-bottom:1px solid #9C9C9C;
  padding-bottom: 5px;
}

.katex-display {
  align-items: left;
  display: block;
  text-align: left;
  margin: 1em 0;
  padding-left: 20px;
}

table {
  border: 1px #a39485 solid;
  font-size: .9em;
  box-shadow: 0 2px 5px rgba(0,0,0,.25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

th {
  text-align: left;
}
  
thead {
  font-weight: bold;
  color: #fff;
  background: #ffc0cb;
}
  
 td, th {
  padding: 1em .5em;
  vertical-align: middle;
}
  
 td {
  border-bottom: 1px solid rgba(0,0,0,.1);
  background: #fff;
}
</style>
