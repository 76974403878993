<template>
  <router-view/>
</template>

<style lang="scss">
@import 'katex/dist/katex.min.css';
@font-face {
  font-family: 'IBMPlexSansKR-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  --Black: #1C1B1F;
  --Backgorund: #F6F6F6;
}

body {
  background-color: var(--Backgorund) !important;
}

#app {
  font-family: 'IBMPlexSansKR-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 !important;
  padding: 0 !important;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
