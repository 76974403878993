import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ProblemSolveView from '@/views/ProblemSolve.vue'
import HomeView from '@/views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/demo',
    name: 'ProblemSolve',
    component: ProblemSolveView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
