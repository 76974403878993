<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid" style="margin-left: 67px;">
      <a class="navbar-logo">π</a>
      <div class="collapse navbar-collapse" style="margin-left: 67px;">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <span class="nav-link">Problems</span>
          </li>
          <li class="nav-item">
            <span class="nav-link">Contests</span>
          </li>
        </ul>
      </div>
      <div style="margin-right: 67px; margin-left: auto;">
        <img src="../assets/User.png">
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
a:hover {
  color: #FFFFFF !important;
}
.navbar {
  padding-left: 40px;
  padding-right: 40px;
  font-family: 'IBMPlexSansKR-Regular';
  background-color: var(--Black);
  height: 64px;
  border-radius: 0px 0px 10px 10px / 0px 0px 10px 10px;
}
.navbar .navbar-nav .nav-link {
  color: #625B71;
  :hover {
    color: #FFFFFF !important;
  }
}
.navbar .navbar-nav .nav-link:hover {
  color: #FFFFFF !important;
}
.nav-item {
  padding: 30px;
}
.navbar-logo {
  text-decoration: none;
  font-family: 'IBMPlexSansKR-Regular';
  position: absolute;
  font-weight: 400;
  font-size: 27px;
  line-height: 35px;
  color: #FFFFFF;

}
ul { list-style:none; cursor:pointer }
li {
  float:left;
  margin-right:10px;
  transition:all .3s ease
}
li:hover { color:#fff; transition:all .3s ease }
li > span { position:relative }

li > span::after {
  content: '';
  width: 0%;
  left: 50%;
  height: 2px;
  display: block;
  position: absolute;
  margin-top: 18px;
  border-bottom: 2px solid #fff;
  transition:all .3s ease;
}
li:hover > span::after {
  content: '';
  width: 100%;
  left: 0px;
  height: 2px;
  display: block;
  position: absolute;
  margin-top: 18px;
  border-bottom: 2px solid #fff;
  transition:all .3s ease;
}
</style>
