
import { defineComponent } from 'vue'
import Navbar from '@/components/NavbarComponent.vue'
import SearchBox from '@/components/SearchBox.vue'
import Footer from '@/components/FooterComponent.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    Navbar,
    SearchBox,
    Footer
  }
})
